import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import tw, { css } from 'twin.macro'
import '../components/PageBannerImage/index.css'
import BlogBanner from '../images/MineralSwimV2_BLOG_HD1_1920x1000.jpg'

import Layout from '../components/layout'

/**
 * truncate string
 * @param {string} string
 * @param {number} limit
 * @returns text...
 */
const truncate = (string, limit) =>
	string.length <= limit ? string : string.slice(0, limit) + '...'

const BlogPage = ({
	data: {
		postsData: { edges: posts },
		blogBannerGatsby
	},
}) => {
	const getObjectPosition = () => {
		if (typeof window !== 'undefined' && window.innerWidth >= 1024) {
			return ['center'];
		} else {
			return ['right'];
		}
	};
	return (
		<Layout className="BlogPage">
			<Helmet>
				<title>Mineral Swim blog: Tips, Benefits and More on Mineral Pools!</title>
				<meta
					name="description"
					content="Check out the latest information, tips and expert advice from Mineral Swim, the market leading mineral pool and ozone purification water treatment system."
				/>
				<meta name="og:site_name" content="Blog" />
				<meta name="keywords" content="Blog" />
				<link rel="canonical" href="https://mineralswim.com/blog/" />
			</Helmet>
			<div className="fixed w-screen h-screen" style={{}} data-scroll
      data-scroll-speed="-10">
				<GatsbyImage
							image={blogBannerGatsby.nodes[0].gatsbyImageData}
							className="w-screen h-full lg:object-center object-right object-cover"
							loading="eager"
							alt="Test"
							objectPosition={getObjectPosition()}
						/>
				</div>
			<main className=""  style={{ position: 'relative', zIndex: 2,}}>
			<header className='w-screen absolute main-page-header bg-center z-10'
						>
							<div className='mx-auto max-w-screen-xl h-full grid grid-cols-6 lg:grid-cols-12 lg:py-10 px-5 md:px-10'>
								<div className='hidden lg:flex col-span-6'></div>
								
								<div className='text-center lg:text-left col-span-6 h-full flex flex-col justify-center md:gap-5 lg:gap-0'>
								<div className='bg-white bg-opacity-20 p-5 rounded-2xl '>
									<h1 className='text-black text-2xl md:text-4xl font-semibold'>Dive into the World of Mineral Swim<span className='text-lg md:text-2xl align-text-top'>&trade;</span> Products</h1>
									<div className='flex w-full justify-center items-center lg:justify-start'>
									<div className='w-20 md:w-16 h-1 mb-4 mt-4 md:mt-2 border-2 border-black'/>	
									</div>
									<p className='text-black md:text-base leading-6'>Explore our blog for the latest insights, tips, and trends on Mineral Swim products. From maintenance advice to benefits for skin and water quality, stay informed and inspired to make the most of your swimming experience.
										</p>				
										<div className='w-full flex justify-center lg:justify-start mt-7 md:mt-10'>			              
										<Link to="/s/?page=blog">             
									<p className='text-white text-base font-bold bg-gray-800 px-8 rounded-lg py-1 hover:bg-gray-200 hover:text-black cursor-pointer uppercase'>Learn more</p>	
									</Link>			
									</div>		
									</div>								
									</div>                
							</div>
								</header>
				<div className='scroll-banner top-0 bg-cover bg-right lg:bg-center z-0 header-with-dimmed-bg' style={{
								backgroundAttachment: 'fixed',
								position: 'relative',
            }}
						data-scroll
      data-scroll-speed="-10"
						>
							
								</div>
								<section id='main' className='relative -mt-56 md:-mt-80 lg:-mt-36 bg-white z-10'>
				<p className="py-6 px-2 w-full text-center font-display font-medium text-lg md:text-2xl max-w-screen-xl mx-auto bg-white z-10">
					Includes the latest information, tips and expert advice from Mineral
					Swim, the market leading mineral and ozone purification water
					treatment system for your backyard pool.
				</p>
				</section>
				<div className='bg-white relative z-10 w-full'>
				<section className="bg-white relative z-10 grid md:grid-cols-2 xl:grid-cols-3 gap-4 max-w-screen-xl mx-auto pb-16 px-2">
					{posts.map(({ node: { title, description, slug, image } }) => (
						<article className="rounded-lg overflow-hidden bg-white text-gray-800 hover:opacity-90 transition duration-500 ease-in-out shadow-xl border-t-white border-b-gray-300 border-t border-b p-2">
						<Link
							to={'/' + slug + '/'}
							className="flex flex-col justify-between h-full"
						>
								{image && image.gatsbyImageData && (
									<GatsbyImage
										image={image.gatsbyImageData}
										alt={image.alt}
										title={image.title}
										css={[css(tw`mb-2 rounded-t-md border-b-blue-500 border-b-4`)]}
									/>
								)}
								<h2 className="px-2 text-lg font-semibold font-display text-gray-700">
									{title}
								</h2>
								<p className="px-2 pb-2 font-medium">{truncate(description, 140)}</p>
							</Link>
						</article>
					))}
				</section>
				</div>
			</main>
		</Layout>
	)
}

export const BlogPageQuery = graphql`
	query blogPageQueryAU {
		postsData: allContentfulPost(
			filter: { tag: { eq: "Mineral Swim" }, node_locale: { eq: "en-AU" } }
			sort: { fields: dateAndTime, order: DESC }
		) {
			edges {
				node {
					title: seoTitle
					description: seoDescription
					slug
					image {
						title
						alt: description
						gatsbyImageData(
							layout: CONSTRAINED
							width: 400
							height: 200
							quality: 50
							placeholder: BLURRED
							formats: [AUTO, WEBP]
						)
					}
				}
			}
		}
		blogBannerGatsby: allContentfulAsset(
			filter: {
				contentful_id: { eq: "2JAmDp1r3dagCtYlrHHEwE" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description

				gatsbyImageData(
					layout: FULL_WIDTH
					width: 1600
					height: 900
					quality: 80
					placeholder: BLURRED
					formats: [AUTO, WEBP]
				)
			}
		}
	}
`

export default BlogPage

BlogPage.propTypes = {
	data: PropTypes.shape({
		postsData: PropTypes.objectOf(PropTypes.array),
	}),
	location: PropTypes.shape({
		search: PropTypes.string,
	}),
}
